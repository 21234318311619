import styled from "styled-components";
import { colors } from "utils";

export const FullPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.BASE};
  z-index: 1000;
`;
