import React from "react";
import { Oval } from "react-loader-spinner";
import { Flex, Text } from "components";
import { colors } from "utils";
import * as Styled from "./LoadingIndicator.styled";

const LoadingIndicator = ({
  size = "medium",
  text = "Loading...",
  fullPage = false,
  fullContainer = false,
  customHeight = null,
}) => {
  if (fullPage) {
    return (
      <Styled.FullPageWrapper>
        <Flex direction="column" align="center">
          <Oval
            color={colors.GOLD}
            height={50}
            width={50}
            secondaryColor={colors.NEUTRAL_20}
          />
          {text && (
            <Text margin="16px 0 0" textAlign="center">
              {text}
            </Text>
          )}
        </Flex>
      </Styled.FullPageWrapper>
    );
  }

  const containerHeight = customHeight || (fullContainer ? "100%" : "auto");
  const containerMinHeight = fullContainer ? "200px" : "auto";

  const dimensions = {
    small: { height: 20, width: 20 },
    medium: { height: 30, width: 30 },
    large: { height: 50, width: 50 },
  }[size] || { height: 30, width: 30 };

  return (
    <Flex
      width="100%"
      height={containerHeight}
      minHeight={containerMinHeight}
      justify="center"
      align="center"
      direction="column"
      padding="24px 0"
    >
      <Flex direction="column" align="center">
        <Oval
          color={colors.GOLD}
          height={dimensions.height}
          width={dimensions.width}
          secondaryColor={colors.NEUTRAL_20}
        />
        {text && (
          <Text margin="12px 0 0" textAlign="center">
            {text}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default LoadingIndicator;
