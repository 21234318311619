import { Button, Dropdown, Flex, Text } from "components/index";
import { generateMonths, reportDayAndYear, useWidth } from "utils";
import { sizes } from "utils/media";
import { ReactComponent as Calendar } from "assets/icons/calendar.svg";
import React from "react";
import { format, subMonths } from "date-fns";

const ReportRangePicker = ({ activeRange, setActiveRange, error }) => {
  const screenWidth = useWidth();
  const currentDay = reportDayAndYear().currentDay;
  const updateRangeFrom = (dateStr) => {
    const dates = dateStr.split(" ");
    setActiveRange({ ...activeRange, monthFrom: dates[0], yearFrom: dates[1] });
  };

  const updateRangeTo = (dateStr) => {
    const dates = dateStr.split(" ");
    setActiveRange({ ...activeRange, monthTo: dates[0], yearTo: dates[1] });
  };

  const updateLast6Months = () => {
    const range = 5;
    const current = reportDayAndYear();
    const previous = subMonths(
      new Date(
        `${current.latestReportingMonth} 01 ${current.latestReportingYear}`
      ),
      range
    );

    const yearFrom = format(previous, "yyyy");
    const monthFrom = format(previous, "MMMM");

    setActiveRange({
      ...activeRange,
      monthFrom,
      yearFrom,
      monthTo: current.latestReportingMonth,
      yearTo: current.latestReportingYear,
    });
  };

  const updateLast3Months = () => {
    const range = 2;
    const current = reportDayAndYear();
    const previous = subMonths(
      new Date(
        `${current.latestReportingMonth} 01 ${current.latestReportingYear}`
      ),
      range
    );

    const yearFrom = format(previous, "yyyy");
    const monthFrom = format(previous, "MMMM");

    setActiveRange({
      ...activeRange,
      monthFrom,
      yearFrom,
      monthTo: current.latestReportingMonth,
      yearTo: current.latestReportingYear,
    });
  };

  const updateYearToDate = () => {
    const current = reportDayAndYear();
    setActiveRange({
      ...activeRange,
      monthFrom: "January",
      yearFrom: current.latestReportingYear,
      monthTo: current.latestReportingMonth,
      yearTo: current.latestReportingYear,
    });
  };

  const updateLastYear = () => {
    const year = new Date().getFullYear() - 1;
    setActiveRange({
      ...activeRange,
      monthFrom: "January",
      yearFrom: year,
      monthTo: "December",
      yearTo: year,
    });
  };

  return (
    <>
      <Flex justify="flex-start" align="flex-start" flexWrap="wrap" column>
        <Text kind="b" margin="0 16px 0 0">
          Date range
        </Text>
        <Flex width="100%" column={screenWidth <= sizes.desktop ? true : false}>
          <Flex
            justify="flex-start"
            flexGrow=".43"
            flexShrink=".57"
            margin="0 0 0 "
            flexWrap="wrap"
            width="100%"
            gap="16px"
          >
            <Dropdown
              name="activePeriod"
              menuItems={generateMonths().reverse()}
              onClick={updateRangeFrom}
              active={activeRange}
              width={screenWidth > sizes.phonePlus ? "200px" : "100%"}
              margin={screenWidth > sizes.phonePlus ? "0 16px 0 0" : "0"}
              header={
                <Flex width="100%">
                  <Text
                    kind="f7"
                    color="NEUTRAL_50"
                  >{`${activeRange.monthFrom} ${activeRange.yearFrom}`}</Text>
                  <Calendar />
                </Flex>
              }
            />
            <Dropdown
              name="activePeriod"
              menuItems={generateMonths().reverse()}
              onClick={updateRangeTo}
              active={activeRange}
              width={screenWidth > sizes.phonePlus ? "200px" : "100%"}
              margin={screenWidth > sizes.phonePlus ? "0 16px 0 0" : "0"}
              header={
                <Flex width="100%">
                  <Text
                    kind="f7"
                    color="NEUTRAL_50"
                  >{`${activeRange.monthTo} ${activeRange.yearTo}`}</Text>
                  <Calendar />
                </Flex>
              }
            />
          </Flex>
          <Flex
            flexGrow=".57"
            flexShrink=".43"
            justify={screenWidth <= sizes.desktop ? "flex-start" : "center"}
            column={screenWidth <= sizes.phonePlus ? true : false}
            width="100%"
            flexWrap="wrap"
            maxHeight={screenWidth <= sizes.phonePlus ? "auto" : "140px"}
            padding="8px 0"
          >
            <Button
              kind="link primary plain"
              size="1em"
              onClick={() => updateYearToDate()}
              width="150px"
              padding="8px 16px"
            >
              <Text kind="nowrap">Year to date</Text>
            </Button>
            <Button
              kind="link primary plain"
              size="1em"
              onClick={() => updateLastYear()}
              width="150px"
              padding="8px 16px"
            >
              <Text kind="nowrap">Last year</Text>
            </Button>
            <Button
              kind="link primary plain"
              size="1em"
              onClick={() => updateLast6Months()}
              width="150px"
              padding="8px 16px"
            >
              <Text kind="nowrap">Last 6 months</Text>
            </Button>
            <Button
              kind="link primary plain"
              size="1em"
              onClick={() => updateLast3Months()}
              width="150px"
              padding="8px 16px"
            >
              <Text kind="nowrap">Last 3 months</Text>
            </Button>
          </Flex>
        </Flex>
      </Flex>
      {error && (
        <Text kind="b f7" margin="8px 0 -16px" color="red">
          {error}
        </Text>
      )}
    </>
  );
};

export default ReportRangePicker;
