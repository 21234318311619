import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as Styled from "./MultiSelect.styled";
import { Flex, Text } from "components";

const MultiSelect = ({
  options,
  name,
  defaultOption = [],
  kind = "default",
  className,
  register,
  width,
  height,
  padding,
  margin,
  accentColor,
  required,
  setSelectedValues,
  ...props
}) => {
  const [selectedValues, setSelectedValuesLocal] = useState(defaultOption);

  const handleCheckboxChange = (value) => {
    setSelectedValuesLocal((prevSelected) => {
      const isSelected = prevSelected.includes(value);
      const updatedSelected = isSelected
        ? prevSelected.filter((v) => v !== value)
        : [...prevSelected, value];

      setSelectedValues(updatedSelected);
      return updatedSelected;
    });
  };

  useEffect(() => {
    setSelectedValuesLocal(defaultOption);
  }, [defaultOption]);

  return (
    <Styled.SelectContainer>
      <Flex column>
        {selectedValues.length > 0 ? (
          <>
            <Text kind="f7">{`Selected ${name}s`}</Text>
            {selectedValues.map((value) => {
              const selectedOption = options.find(
                (option) => option.value === value
              );
              return (
                <Text kind="f7 b" key={selectedOption?.id}>
                  {selectedOption?.label}
                </Text>
              );
            })}
          </>
        ) : (
          <Text kind="f7 b">{`Select ${name}s below`}</Text>
        )}
      </Flex>

      <Styled.CheckboxContainer
        name={name}
        className={kind || className}
        width={width}
        height={height}
        padding={padding}
        margin={margin}
        border={accentColor}
        required={required}
        {...props}
      >
        {options.map(({ value, label }) => {
          const isSelected = selectedValues.includes(value);

          return (
            <Styled.CheckboxWrapper key={`checkbox-wrapper-${value}`}>
              <Styled.Checkbox
                type="checkbox"
                id={`checkbox-${value}`}
                value={value}
                checked={isSelected}
                onChange={() => handleCheckboxChange(value)}
              />
              <Text
                kind="f7"
                htmlFor={`checkbox-label-${value}`}
                margin="0 20px 0 0"
              >
                {name === "funeralHome" ? `${value} | ${label}` : label}
              </Text>
            </Styled.CheckboxWrapper>
          );
        })}
      </Styled.CheckboxContainer>
    </Styled.SelectContainer>
  );
};

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string,
  defaultOption: PropTypes.array,
  kind: PropTypes.string,
  register: PropTypes.object,
  height: PropTypes.string,
  width: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  accentColor: PropTypes.string,
  required: PropTypes.bool,
  setSelectedValues: PropTypes.func.isRequired,
};

export default MultiSelect;
