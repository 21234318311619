import React, { useState, useRef } from "react";
import * as Styled from "./Header.styled";
import { Flex, Text } from "components";
import { useOutsideClick, logout } from "utils";
import { useNavigate } from "@reach/router";
import { useUser, useToken } from "providers/AuthProvider";

const Header = ({ mobile, menuOpen, toggleMenu }) => {
  const [dropdownOpen, toggleDropdown] = useState(false);
  const [user, setUser] = useUser();
  const [, setToken] = useToken();
  const navigate = useNavigate();
  const role = sessionStorage.getItem("role");
  const viewAs = sessionStorage.getItem("viewAs");

  const handleLogout = () => {
    setUser(null);
    setToken(null);
    logout();
  };

  const handleProfileClick = () => {
    if (menuOpen) {
      toggleMenu();
    }
    toggleDropdown(!dropdownOpen);
  };

  const profileRef = useRef();
  useOutsideClick(profileRef, () => toggleDropdown(false));

  return (
    <>
      {dropdownOpen && (
        <Styled.DropDown>
          {(user?.type === "superAdmin" ||
            user?.corporation ||
            user?.funeral_homes) && (
            <Styled.Link onClick={() => navigate("/dashboard")}>
              <Text kind="f8 b">Dashboard</Text>
            </Styled.Link>
          )}

          {user?.lead_partner && (
            <Styled.Link onClick={() => navigate("/lead-partner/dashboard")}>
              <Text kind="f8 b">Leads</Text>
            </Styled.Link>
          )}

          <Styled.Link>
            <Text kind="f8 b" onClick={handleLogout}>
              Logout
            </Text>
          </Styled.Link>
        </Styled.DropDown>
      )}
      <Styled.Wrapper mobile={mobile}>
        <Flex justify="flex-end" align="center" height="100%">
          <div ref={profileRef}>
            <Styled.ClickHandler onClick={handleProfileClick}>
              <Styled.Logo />
              <Text kind="f8" cursor="pointer" onClick={handleProfileClick}>
                {viewAs && role ? `Viewing As: ${role}` : user?.user_name}
              </Text>
            </Styled.ClickHandler>
          </div>
        </Flex>
      </Styled.Wrapper>
    </>
  );
};

export default Header;
