const font = {
  _25: ".4rem",
  _50: ".8rem",
  _75: "1rem",
  _100: "1.2rem",
  _150: "1.4rem",
  _200: "1.6rem",
  _250: "2rem",
  _300: "2.4rem",
  _400: "2.8rem",
  _450: "3.2rem",
  _500: "3.6rem",
  _550: "4rem",
  _600: "4.8rem",
  _700: "5.6rem",
  _800: "6.4rem",
  _900: "9.6rem",
  _1000: "12.8rem",
};

const readable = {
  HEADER: font._300,
  HEADER_HEIGHT: font._400,
  SUB_HEADER: font._150,
  SUB_HEADER_HEIGHT: font._200,
  REGULAR: font._100,
  REGULAR_HEIGHT: font._150,
  MOBILE_HEADER: font._200,
  MOBILE_HEADER_HEIGHT: font._250,
  MOBILE_SUB_HEADER: font._150,
  MOBILE_SUB_HEADER_HEIGHT: font._200,
  MOBILE_REGULAR: font._100,
  MOBILE_REGULAR_HEIGHT: font._150,
};

export default { ...font, ...readable };
