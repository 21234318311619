import { useCallback, useEffect, useState } from "react";
import { post } from "api/request";
import { setDay } from "utils";

export default ({
  isParent,
  homeId,
  activeHome,
  activeMonth,
  activeYear,
  activeMonthTo,
  activeYearTo,
  callback,
  token,
  user,
}) => {
  const [response, setResponse] = useState({
    brand_reach: {},
    funeral_home: {},
    newspaper_traffic: [],
    fhls: {},
    market_share: {},
    overview: {},
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const query = useCallback(
    async (body) => {
      try {
        setError(null);
        setIsLoading(true);
        const data = await post("funeralHomeData", token, body);
        console.log("data", data);
        setResponse(data);
        setIsLoading(false);
      } catch (e) {
        setError(e);
        setIsLoading(false);
      }
    },
    [token]
  );

  useEffect(() => {
    const from = setDay(activeMonth);
    let to;
    if (activeMonth && activeYearTo) {
      to = setDay(activeMonthTo);
    }

    const startDate = `${activeYear}-${from.monthAsInteger}-01T00:00:00-0700`;
    let endDate = `${activeYear}-${from.monthAsInteger}-${from.lastDayAsInteger}T00:00:00-0700`;

    if (to) {
      endDate = `${activeYearTo}-${to.monthAsInteger}-${to.lastDayAsInteger}T00:00:00-0700`;
    }

    let body = {
      fh_id: activeHome,
      start_date: startDate,
      end_date: endDate,
    };
    isParent && (body.parent_fh_id = activeHome);
    // Removed "user" as condition of calling query; need to check for side effects
    activeHome && query(body);
  }, [
    homeId,
    activeHome,
    activeMonth,
    activeYear,
    isParent,
    callback,
    query,
    user,
    activeMonthTo,
    activeYearTo,
  ]);

  return { response, error, isLoading };
};
