import styled from "styled-components";
import { colors, media } from "utils";

const PageContainer = styled.div`
  width: 100%;
  ${media.tabletPlus`
    width: calc(100% - 256px);
  `}
  top: 88px;
  ${media.tabletPlus`
    left: 256px;
    &::-webkit-scrollbar {
          display: block;
          width: 8px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px transparent;
        }
        &::-webkit-scrollbar-thumb {
          background-color: ${colors.NEUTRAL_40};
          outline: none;
        }
  `}
  bottom: 0;
  position: fixed;
  background: ${colors.BASE};
  overflow-x: scroll;
  z-index: 999;

  .body {
    padding: 40px 40px 64px;
    z-index: 999998;
    min-height: calc(100vh - 369px);
  }

  .footer {
    background: ${colors.NEUTRAL_20};
  }
`;

export default PageContainer;
