import styled from "@emotion/styled";
import { colors, sizing as fontSize } from "utils";

export const SelectStyled = styled.select`
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  min-height: ${({ minHeight }) => minHeight};
  max-height: ${({ maxHeight }) => maxHeight};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  background: ${({ background }) => background};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  font-size: ${({ size }) => fontSize[size] || size};
  text-overflow: ${({ textOverflow }) => textOverflow};
  white-space: ${({ whiteSpace }) => whiteSpace};
  overflow: ${({ overflow }) => overflow};
  overflow-y: ${({ overflowY }) => overflowY};
  overflow-x: ${({ overflowX }) => overflowX};
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-shrink: ${({ flexShrink }) => flexShrink};

  &.default {
    padding: ${({ padding = "12px 10px" }) => padding};
    border: none;
    width: ${({ width = "auto" }) => width};
    color: ${colors.NEUTRAL_04};
    font-size: ${({ size = "_180" }) => fontSize[size] || size};
    line-height: 24px;
    font-weight: normal;
    background: ${colors.WHITE};
    border: 1px solid ${colors.NEUTRAL};
    border-radius: 2px;
    &:focus {
      outline: none;
    }
  }

  &.regular {
    padding: 16px 18px;
    width: ${({ width = "100%" }) => width};
    border: 1px solid ${colors.NEUTRAL};
    border-radius: 4px;
    font-size: ${({ size = fontSize.REGULAR }) => fontSize[size] || size};
    line-height: ${({ lineHeight = fontSize.REGULAR_HEIGHT }) =>
      fontSize[lineHeight] || lineHeight};
  }

  &:focus {
    border: 1px solid transparent;
    outline: 1px solid ${colors.GOLD};
  }

  &.error {
    border: 1px solid ${colors.RED};
    outline: 1px solid ${colors.RED};
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  background: white;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Checkbox = styled.input`
  margin-right: 8px;
  cursor: pointer; // Ensures that it indicates interactivity
  width: 16px; // Define a specific width
  height: 16px; // Define a specific height
`;

SelectStyled.defaultProps = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
};
