import React, { useState, useEffect } from "react";
import {
  getAllFuneralHomes,
  linkFuneralHomesToCorporation,
} from "api/funeral-home";
import {
  GenericModal,
  Button,
  Text,
  Flex,
  MultiSelect,
  Input,
} from "components";
import { useToken } from "providers/AuthProvider";
import { colors } from "utils";
import { useDebounce } from "hooks";
import { Oval } from "react-loader-spinner";

const LinkFuneralHomeModal = ({
  closeModal,
  corporation,
  linkedFuneralHomes,
  setRefresh,
}) => {
  const [token] = useToken();
  const [funeralHomes, setFuneralHomes] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(
    () => {
      fetchFuneralHomes(currentPage, searchTerm);
    },
    1000,
    [searchTerm]
  );

  const handleConnect = async () => {
    try {
      await linkFuneralHomesToCorporation(token, corporation.corporation_id, {
        parent_fh_ids: selectedValues,
      });
      setRefresh(new Date());
    } catch (err) {
      console.error("Error linking funeral homes:", err);
    }
    closeModal();
  };

  const fetchFuneralHomes = async (page = 1, search = "") => {
    setLoading(true);
    let params = "";
    if (search !== "") params += `search_term=${search}&`;
    params += `page=${page}`;

    if (params.length !== 0) params = `?parent=true&${params}`;
    try {
      const res = await getAllFuneralHomes(token, params);
      const processedFuneralHomes = res.map((funeralHome) => ({
        value: funeralHome.fh_id,
        label: funeralHome.fh_display,
      }));
      setFuneralHomes(processedFuneralHomes);
      setTotalPages(Math.ceil(res[0]?.full_count / 20)); // 20 items per page
    } catch (err) {
      console.error("Error fetching funeral homes:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFuneralHomes(currentPage, debouncedSearchTerm);
  }, [currentPage, debouncedSearchTerm]);

  useEffect(() => {
    setSelectedValues(
      linkedFuneralHomes.map((funeralHome) => funeralHome.parent_fh_id)
    );
  }, [linkedFuneralHomes]);

  const handleSearchChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  return (
    <>
      <GenericModal
        padding="0"
        minHeight="500px"
        minWidth="800px"
        maxWidth="800px"
        close={() => closeModal()}
      >
        <Flex column margin="48px 0" flexGrow="1" justify="space-between">
          <Text kind="f4 b" margin="12px 0 0 0">
            {`Link Homes to ${corporation.corporation_name}`}
          </Text>

          <Flex
            as="input"
            type="text"
            placeholder="Search Funeral Homes 🔎"
            value={searchTerm}
            onChange={handleSearchChange}
            margin="8px 0"
            width="100%"
          />

          {loading ? (
            <Flex justify="center" margin="16px 0" minHeight="191px">
              <Oval type="Oval" color={colors.GOLD} width={20} height={100} />
              <Text>Loading...</Text>
            </Flex>
          ) : funeralHomes.length > 0 ? (
            <MultiSelect
              margin="8px 8px 8px 0px"
              width="100%"
              flexGrow="1"
              defaultOption={selectedValues}
              setSelectedValues={setSelectedValues}
              name="funeralHome"
              options={funeralHomes}
            />
          ) : (
            <Text kind="f7 b">
              No funeral homes found with that search term.
            </Text>
          )}

          <Flex column justify="center" maxWidth="512px">
            <Text kind="f9" align="center">
              {`The list only shows Parent Homes. Chief Corporation Accounts sees a rolled up view of all the funeral homes linked to them.`}
            </Text>
          </Flex>

          <Flex justify="center" margin="16px 0">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Text margin="0 16px">{`Page ${currentPage} of ${
              totalPages || 1
            }`}</Text>
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </Flex>

          <Flex
            column
            width="100%"
            justify="center"
            margin="35px 0 0 0"
            padding="0 32px"
            gap="16px"
          >
            <Flex gap="16px">
              <Button
                kind="outline"
                padding="10px 22px"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
              <Button
                kind="primary"
                padding="10px 22px"
                onClick={handleConnect}
                disabled={!selectedValues.length}
              >
                <Text color={colors.WHITE}>Update Link</Text>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </GenericModal>
    </>
  );
};

export default LinkFuneralHomeModal;
