import React from "react";
import { Button, Text, Flex, LoadingIndicator } from "components";
import styled from "styled-components";
import { colors, media, logout } from "utils";
const ResponsiveDisplayHandler = styled.div`
  &:before {
    content: "Loading...";
    width: 100%;
    height: 100%;
    ${media.tabletPlus`
      content: "";
      width: 0%;
      height: 0%;
    `}
  }
  > div {
    display: none;
    ${media.tabletPlus`
      display: block;
    `}
  }
`;

const Header = styled.div`
  background: ${colors.WHITE};
  width: calc(100% - 256px);
  position: fixed;
  right: 0;
  padding-right: 28px;
  height: 88px;
  z-index: 999999;
`;

const Sidebar = styled.div`
  width: 256px;
  background: ${colors.BLUE_130};
  height: 100vh;
  overflow: scroll;
  position: fixed;
  z-index: 9999999;
  &.hide-in-mobile {
    display: none;
    ${media.tabletPlus`
      display: block;
    `}
  }
`;

const Wrapper = styled.div`
  width: 100%;
  ${media.tabletPlus`
    width: calc(100% - 256px);
  `}
  top: 88px;
  ${media.tabletPlus`
    left: 256px;
  `}
  bottom: 0;
  position: fixed;
  background: ${colors.BASE};
  overflow-x: scroll;
  z-index: 999;
  .body {
    padding: 40px 40px 64px;
    z-index: 999998;
  }
  .footer {
    background: ${colors.NEUTRAL_20};
  }
`;

const Loading = ({ error }) => {
  return (
    <ResponsiveDisplayHandler>
      <Header />
      <Sidebar />
      <Wrapper>
        <div className="body">
          <Flex column>
            {error || (
              <Flex width="100%" justify="center" margin="30vh 0 0">
                <LoadingIndicator size="large" text="Loading..." />
              </Flex>
            )}
            {error && (
              <Button
                margin="12px 0 0"
                kind="blue short"
                onClick={() => logout({ from: "loading" })}
              >
                <Text kind="f9" weight="bold">
                  Return to login
                </Text>
              </Button>
            )}
          </Flex>
        </div>
      </Wrapper>
    </ResponsiveDisplayHandler>
  );
};

export default Loading;
