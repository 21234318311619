import React, { useState, useEffect } from "react";
import { getAllUsersByType, linkUsersToCorporation } from "api/users";
import { GenericModal, Button, Text, Flex, MultiSelect } from "components";
import { useToken } from "providers/AuthProvider";
import { colors } from "utils";
import { Oval } from "react-loader-spinner";
const LinkUserModal = ({
  closeModal,
  corporation,
  linkedUsers,
  setRefresh,
}) => {
  const [token] = useToken();

  const [users, setUsers] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleConnect = async () => {
    try {
      const res = await linkUsersToCorporation(
        token,
        corporation.corporation_id,
        {
          user_ids: selectedValues,
        }
      );
      setRefresh(new Date());
    } catch (err) {
      console.error("Error linking users to corporation", err);
    }

    closeModal();
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const res = await getAllUsersByType(token, "chiefCorporationAdmin");
        const processedUsers = res.map((user) => ({
          value: user.user_id,
          label: user.email,
        }));
        setUsers(processedUsers);
      } catch (err) {
        console.error("Error fetching users", err);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    if (!linkedUsers) return;
    setSelectedValues(linkedUsers.map((user) => user.user_id));
  }, [linkedUsers]);

  return (
    <>
      <GenericModal
        padding="48px 32px 43px 32px"
        minHeight="500px"
        minWidth="500px"
        maxWidth="800px"
        close={() => closeModal()}
      >
        <Flex column margin="48px 0" flexGrow="1" justify="space-between">
          <Text kind="f4 b" margin="12px 0 0 0">
            {`Link Users to ${corporation.corporation_name}`}
          </Text>

          {loading ? (
            <Flex justify="center" margin="16px 0" minHeight="191px">
              <Oval type="Oval" color={colors.GOLD} width={20} height={100} />
              <Text>Loading...</Text>
            </Flex>
          ) : users.length > 0 ? (
            <MultiSelect
              margin="8px 8px 8px 0px"
              width="100%"
              flexGrow="1"
              defaultOption={selectedValues}
              setSelectedValues={setSelectedValues}
              name="user"
              options={users}
            />
          ) : (
            <Text kind="f9" align="center">
              {`No users found`}
            </Text>
          )}

          <Flex column justify="center" maxWidth="512px">
            <Text kind="f9" align="center">
              {`The list only shows Chief Corporation Admins. If your user is not in the list,`}
            </Text>
            <Text kind="f9" align="center">
              {`navigate to the Users page to change their type to`}{" "}
              <span style={{ fontWeight: "bold" }}>
                Chief Corporation Admin.
              </span>{" "}
            </Text>
          </Flex>
          <Flex
            column
            width="100%"
            justify="center"
            margin="35px 0 0 0"
            padding="0 32px"
            gap="16px"
          >
            <Flex gap="16px">
              <Button
                kind="outline"
                padding="10px 22px"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
              <Button
                kind="primary"
                padding="10px 22px"
                onClick={handleConnect}
                disabled={!selectedValues ? true : false}
              >
                <Text color={colors.WHITE}>Update Link</Text>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </GenericModal>
    </>
  );
};

export default LinkUserModal;
