// imports and variables alphabetized when possible for readability
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import * as Styled from "./DefaultTable.styled";
import {
  useAsyncDebounce,
  useExpanded,
  useFlexLayout,
  useFilters,
  useGlobalFilter,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
  usePagination,
} from "react-table";
import * as config from "./DefaultTable.config";
import { useDebounce } from "hooks";
import { Flex, Pagination, Filters, Text } from "components";
import { IoClose } from "react-icons/io5";
import { VscLoading } from "react-icons/vsc";
import { ImSortAmountDesc, ImSortAmountAsc } from "react-icons/im";
import { useWidth } from "utils";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { BsCircleFill } from "react-icons/bs";

// ============================ SEARCH BOX GLOBAL FILTER ============================

function GlobalFilter({
  type,
  searchLabel,

  // React Table - Filtering
  globalFilter,
  preGlobalFilteredRows,
  setGlobalFilter,

  // Backend - Pagination & Search
  // 🚨 these cannot be destructured because they are 'undefined' with auth0 table
  fhPagination,
  fhSearch,
}) {
  // state
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (type === "funeralHomes") {
      setValue(fhSearch.searchTerm);
    } else {
      setValue(globalFilter);
    }
    // eslint-disable-next-line
  }, [type]);

  // methods
  const globalSearchHandler = useAsyncDebounce((searchValue) => {
    setGlobalFilter(searchValue || undefined);
  }, 200);
  useDebounce(
    () => {
      if (type === "funeralHomes") {
        if (value === "") {
          fhSearch.setSearchTerm(null);
        } else {
          fhSearch.setSearchTerm(value);
        }
        fhPagination.setPagination(1);
      }
    },
    500,
    [value, type]
  );

  const reset = () => {
    setValue(null);
    if (type === "funeralHomes") {
      fhSearch.setSearchTerm(null);
      fhPagination.setPagination(1);
    } else {
      setGlobalFilter(null);
    }
  };

  return (
    <Styled.SearchBox margin="0 0 24px 0">
      {searchLabel || "Search"}:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          e.stopPropagation();
          setValue(e.target.value);
          type !== "funeralHomes" && globalSearchHandler(e.target.value);
        }}
        placeholder={
          type === "funeralHomes"
            ? `${fhPagination.resultsCount} records...`
            : `${count} records...`
        }
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          lineHeight: "2rem",
          fontSize: "1.2rem",
          border: "0",
        }}
      />
      <IoClose onClick={reset} style={{ cursor: "pointer" }} />
    </Styled.SearchBox>
  );
}

// ============================ END SEARCH BOX GLOBAL FILTER ============================

const headerProps = (props, { column }) => {
  return getStyles(props, column.align);
};

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "flex-start") => [
  props,
  {
    style: {
      justifyContent: align,
      alignItems: "center",
      display: "flex",
    },
  },
];

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

// ============================ TABLE ============================

function Table({
  type,
  columns,
  originalData,
  hiddenColumns,
  searchLabel,
  showCheckboxes,
  hasFilter,
  isLoading,
  withSortIcon,

  // Backend - Filtering
  fhFilterConfig,

  // Backend - Pagination
  fhPagination,

  // Backend - Searching
  fhSearch,

  ...rest
}) {
  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const [data, setData] = useState(originalData);

  // keeps the whole table data always up-to-date
  useEffect(() => {
    setData(originalData);
  }, [originalData]);

  const {
    getTableProps,
    headerGroups,
    page,
    preGlobalFilteredRows,
    prepareRow,
    setGlobalFilter,
    state,
    state: { expanded, pageIndex, pageSize },
    isExpandable,
    drawer,
    pageOptions,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data: hasFilter ? data : originalData,
      defaultColumn,
      initialState: {
        hiddenColumns: hiddenColumns,
        pageIndex: 0,
        pageSize: 20,
      },
      ...rest,
    },
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    useExpanded, // useExpanded plugin hook MUST be put after useSortBy plugin hook, so ignore alphabetization guideline here
    usePagination,
    useRowSelect, // useRowSelect plugin hook MUST be put after useSortBy plugin hook, so ignore alphabetization guideline here
    (hooks) => {
      // NOTE: CODE FOR A CHECKBOX COLUMN; WHICH WE WILL WANT AT SOME POINT
      showCheckboxes &&
        hooks.allColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            disableResizing: true,
            minWidth: 35,
            width: 35,
            maxWidth: 35,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        // fix the parent group of the selection button to not be resizable
        const selectionGroupHeader = headerGroups[0].headers[0];
        selectionGroupHeader.canResize = false;
      });
    }
  );

  return (
    <>
      <div {...getTableProps()} className="table">
        <Flex justify="center" padding="20px">
          <GlobalFilter
            {...{ type, searchLabel }}
            // React Table - Filtering
            globalFilter={state.globalFilter}
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            // Backend - Pagination
            fhPagination={fhPagination}
            // Backend - Searching
            fhSearch={fhSearch}
          />
        </Flex>
        {/*HEADER*/}
        <div>
          {headerGroups.map((headerGroup) => (
            <div key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <div
                  key={column.id}
                  {...column.getHeaderProps(headerProps)}
                  className="th"
                >
                  <Flex {...column.getSortByToggleProps()}>
                    {!!withSortIcon && (
                      <>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Text className="sort-icon">
                              <ImSortAmountDesc />
                            </Text>
                          ) : (
                            <Text className="sort-icon">
                              <ImSortAmountAsc />
                            </Text>
                          )
                        ) : column.canSort ? (
                          <Text className="sort-icon">
                            <ImSortAmountDesc />
                          </Text>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                    <Text
                      kind={
                        !withSortIcon && column.isSorted
                          ? "f8 b underline"
                          : "f8 b"
                      }
                    >
                      {column.render("Header")}
                    </Text>
                  </Flex>
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? "isResizing" : ""
                      }`}
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>

        {!isLoading ? (
          data.length === 0 ? (
            <Text kind="f5" align="center">
              No Results for current query
            </Text>
          ) : (
            <div className="tbody">
              {page.map((row) => {
                prepareRow(row);
                return (
                  <React.Fragment key={row.id}>
                    <div
                      {...row.getRowProps()}
                      className={`tr ${isExpandable && "isExpandable"}`}
                      {...(isExpandable && {
                        onClick: (e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          row.toggleRowExpanded();
                        },
                      })}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <div
                            key={cell.row.id}
                            {...cell.getCellProps(cellProps)}
                            className="td"
                          >
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                    {expanded[row.id] && drawer(row, { ...rest })}
                  </React.Fragment>
                );
              })}
            </div>
          )
        ) : (
          <Flex column>
            <Styled.Loader>
              <VscLoading />
            </Styled.Loader>
            Loading...
          </Flex>
        )}
      </div>
      {!isLoading && data.length !== 0 && (
        <Pagination
          type={type}
          // React Table - Pagination
          {...{
            pageOptions,
            canPreviousPage,
            canNextPage,
            pageCount,
            pageIndex,
            gotoPage,
            nextPage,
            previousPage,
          }}
          // Backend - Pagination
          fhPagination={{ ...fhPagination, pageSize }}
        />
      )}
    </>
  );
}

// ============================ END TABLE ============================

function App({
  type,
  data,
  className,
  withSortIcon = true,
  showScrollControlCenter,

  hasFilter,
  isLoading,

  // Backend - Filtering
  fhFilterConfig,

  // Backend - Pagination
  fhPagination,

  // Backend - Searching
  fhSearch,

  ...rest
}) {
  const columns = useMemo(() => config.columns[type], [type]);
  const memoData = useMemo(
    () => data,
    // eslint-disable-next-line
    [data, type]
  );
  const [filteredData, setFilteredData] = useState(memoData);

  // Variables to manually manipulate horizontal scroll
  const tableWrapperRef = useRef("");
  const pageWidth = useWidth();

  return (
    <Styled.OverflowContainer>
      {hasFilter && fhFilterConfig && (
        <Flex position="sticky" top="10px">
          <Filters
            originalData={memoData}
            setData={setFilteredData}
            // Backend - Filtering
            filterConfig={fhFilterConfig}
            // Backend - Pagination
            fhPagination={fhPagination}
          />
        </Flex>
      )}
      <Styled.Wrapper
        {...(className && { className: className })}
        {...(showScrollControlCenter && { margin: "60px 0 0" })}
        ref={tableWrapperRef}
      >
        {showScrollControlCenter && (
          <Flex position="absolute" top="-4px" right="0px">
            <Flex
              gap="4px"
              margin="12px"
              boxShadow="0px 2px 5px 0px rgb(0 20 30 / 30%)"
              borderRadius="24px"
            >
              <Flex
                onClick={() =>
                  (tableWrapperRef.current.scrollLeft =
                    tableWrapperRef.current.scrollLeft - pageWidth / 2)
                }
                padding="4px 8px 0"
                cursor="pointer"
              >
                <Text size="28px">
                  <GoChevronLeft />
                </Text>
              </Flex>
              <Flex className="circle small" padding="4px 8px 0">
                <Text size="16px">
                  <BsCircleFill />
                </Text>
              </Flex>
              <Flex
                onClick={() =>
                  (tableWrapperRef.current.scrollLeft =
                    tableWrapperRef.current.scrollLeft + pageWidth / 2)
                }
                padding="4px 8px 0"
                cursor="pointer"
              >
                <Text size="28px">
                  <GoChevronRight />
                </Text>
              </Flex>
            </Flex>
          </Flex>
        )}
        <Table
          withSortIcon={!!withSortIcon}
          originalData={hasFilter ? filteredData : memoData}
          hiddenColumns={config.hiddenColumns[type]}
          {...{
            hasFilter,
            isLoading,
            type,
            columns,
            fhSearch,
            fhPagination,
          }}
          // Backend - Filtering
          filterConfig={fhFilterConfig}
          {...rest}
        />
      </Styled.Wrapper>
    </Styled.OverflowContainer>
  );
}

export default App;
