const auth0EventTypes = {
  s: "Successful Login",
  sepft: "Successful Login",
  seacft: "Successful Exchange of Authorization Code for Access Token",
  ssa: "Successful Silent Authentication",

  slo: "Successful Logout",

  f: "Failed Login",
  fp: "Incorrect Password",
  fu: "Invalid Email/Username",

  cls: "Passwordless login code/link sent",
  cs: "Passwordless login code sent",

  limit_mu:
    "Blocked IP: 100 Failed Logins with Different Usernames in 24 Hours",
  limit_wc: "Blocked IP: 10 Failed Logins for Single Account",
  limit_sul: "Temporary User Block: >20 logins / minute from same IP",
  ublkdu: "USer Login Block Released",

  pla: "Pre-login assesment: monitors bot detection",

  w: "Warnings During Login",
};

export default auth0EventTypes;
