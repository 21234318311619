import { get, put, patch, post } from "./request";

export const putUserById = async (id, token, body) => {
  return put(`users/${id}`, token, body);
};

export const getAllUsers = async (token) => {
  return get("users", token);
};

export const getAllUsersByType = async (token, type) => {
  return get(`users/type/${type}`, token);
};

export const linkUsersToCorporation = async (
  token,
  corporation_id,
  payload
) => {
  return post(`corporations/${corporation_id}/link-users`, token, payload);
};

export const createUser = async (token, payload) => {
  return post("users/add-user", token, payload);
};

export const archiveUser = async (token, payload) => {
  return patch(`user/archiveUser`, token, payload);
};

export const getUsersByFhId = async (fh_id, token) => {
  return get(`funeral-home-users/${fh_id}`, token);
};

export const updateUserReceiveMonthlyEmail = async (
  user_id,
  fh_id,
  token,
  body
) => {
  return patch(
    `funeral-home-users/${fh_id}/update-user/${user_id}`,
    token,
    body
  );
};

//Unused
export const getParentUserByFhParentId = async (fh_id, token) => {
  return get(`funeral-home-parent-user/${fh_id}`, token);
};

//Unused
export const getGrandParentUserByCorporationId = async (corp_id, token) => {
  return get(`funeral-home-grandparent-user/corporations/${corp_id}`, token);
};
